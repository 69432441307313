export const trainingTypeOptions = [
    {
        label: '培训学习',
        value: '1',
        bg: require('@/www/assets/images/message/bg_jjtz.png')
    },
    {
        label: '培训答题',
        value: '2',
        bg: require('@/www/assets/images/message/bg_yqts.png')
    },
    {
        label: '岗前宣',
        value: '3',
        bg: require('@/www/assets/images/message/bg_ptgz.png')
    }
]
